import React, { Component } from 'react'; 
class Header extends Component {
  render() {

    if(this.props.data){
      var name = this.props.data.name;
      var displaypic= "images/"+this.props.data.image;
      
      var occupation= this.props.data.occupation;
      var description= this.props.data.description;
      var city= this.props.data.address.city;
      var networks= this.props.data.social.map(function(network){
        return <li key={network.name}><a href={network.url}><i className={network.className}></i></a></li>
      })
    }

    return (
      <header id="home">

      <nav id="nav-wrap">

         <a className="mobile-btn" href="#nav-wrap" title="Show navigation">Show navigation</a>
	      <a className="mobile-btn" href="#home" title="Hide navigation">Hide navigation</a>

         <ul id="nav" className="nav">
            <li className="current"><a className="smoothscroll" href="#home">Home</a></li>
            {/* <li><a className="smoothscroll" href="#updates">Updates</a></li> */}
	         <li><a className="smoothscroll" href="#bod">Our team</a></li>
	         <li><a className="smoothscroll" href="#kidscorner">Kids club</a></li>
            <li><a className="smoothscroll" href="#media">Media</a></li>
            {/* <li><a className="smoothscroll" href="#testimonials">Know our members</a></li> */}
            {/* <li><a href="https://docs.google.com/forms/d/e/1FAIpQLSdP8CcX-KP9PC2iRhd_WTmARlooFvyjEb0Jqimy_75zEpAyhg/viewform?usp=sf_link" target="_blank">Register(Kids Club)</a></li> */}
            {/*<li><a href="https://forms.gle/8G9GFqUS6MKVV7AQ8" target="_blank">Pookkalm Competition</a></li>*/}
            {/* <li><a className="smoothscroll" href="#pos">Renew Membership</a></li> */}
            {/* <li><a className="smoothscroll" href="#mob">Malayalee owned business</a></li> */}
            <li><a className="smoothscroll" href="#about">About</a></li>
            <li><a className="smoothscroll" href="#footer">Our Sponsors</a></li>
            <li><a href="https://docs.google.com/forms/d/1YkwoYbXrVj697HS3pJ6Jx05HJrHliKithmfjtG_bRvE/edit" target="_blank">Become a Member</a></li>
            {/* <li><a className="smoothscroll" href="#contact">Contact</a></li> */}
            {/* <li><a className="smoothscroll" href="#join">Join</a></li> */}
         </ul>

      </nav>

      <div className="row banner">
         <div className="banner-text">
            <div className='row-line'>
               <div className='item'>
                     <img src={displaypic} alt="Masconn.org" />
                  </div>
            </div>
            <div className='row-line'>
               <div className='item'>
                  <h1 className="responsive-headline">{name}</h1>
               </div>
            </div>
            <div className='row-line'>
            <div className='item'>
                  <h4 className="responsive-headline">{description}</h4>
               </div>
            </div>
            {/* <h4>MASCONN(Malayalee Association Of Southern Connecticut) is a non-political, non-religious forum to strengthen Malayalee culture 
               and tradition and to give special emphasis to the development of the new generation of 
               expatriate Malayalees. For the younger generation, we pass on the cultural heritage, tradition, 
               the rarely cherished legacy and values while they are integrating into the American culture.
               Our Mission is to preserve, cherish, celebrate, strengthen, uphold the traditional culture and
                heritage of Kerala by providing the MASCONN platform for members. We celebrate festivals of Kerala like 
                Onam, Christmas/New Year &amp; have periodic get-togethers,short-trips or we just hang out with one 
                another occasionally!
               This is a great platform to meet all the youths of the community and to learn more about our culture. 
               It is an opportunity for all the children and young adults to share their ideas, talents, and thoughts and 
               also to get to know each other within our community.</h4>
            <hr /> */}
            <ul className="social">
               {networks}
            </ul>
         </div>
      </div>

      <p className="scrolldown">
         <a className="smoothscroll" href="#updates"><i className="icon-down-circle"></i></a>
      </p>

   </header>
    );
  }
}

export default Header;
