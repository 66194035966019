import React, { Component } from 'react';

class Footer extends Component {
  render() {

    if(this.props.data.main){
      var networks= this.props.data.main.social.map(function(network){
        return <li key={network.name}><a href={network.url}><i className={network.className}></i></a></li>
      })
    }
    if (this.props.data.sponsors && this.props.data.sponsors.data) {
      console.log(this.props.data.sponsors.data);
      
      var sponsors = this.props.data.sponsors.data.map((sponsor, index) => (
        <div key={index} className='sponsor'>
          <img src={sponsor} alt={`Sponsor ${index + 1}`} />
        </div>
      ));
    }

return (


<footer>
  <section id="footer">
    <div id="sponsors" className="sponsors-container">
      {sponsors}
    </div>
     <div className="row">
        <div className="twelve columns">
           <ul className="social-links">
              {networks}
           </ul>

           <ul className="copyright">
              <li>&copy; Copyright {new Date().getFullYear()} Kaushik Prakash</li>
           </ul>

        </div>
        <div id="go-top"><a className="smoothscroll" title="Back to Top" href="#home"><i className="icon-up-open"></i></a></div>
     </div>
     </section>
  </footer>
    );
  }
}

export default Footer;
